import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Hello, World!</h1>
	<p>Please kill me now</p>
    </div>
  );
}

export default App;
